export const localOrderIdKey = (slug?: string) =>
  slug ? `_local_${slug}_order_id` : '_local_order_id'
export const localOrderKey = (slug?: string) => (slug ? `_local_${slug}_order` : '_local_order')
export const localBuyNowOrderKey = (slug?: string) =>
  slug ? `_local_${slug}_buynow_order` : '_local_buynow_order'
export const localPurchasedOrderKey = (slug?: string) =>
  slug ? `_local_${slug}_purchased_order` : '_local_purchased_order'
export const localIsBuyNowKey = (slug?: string) =>
  slug ? `_local_${slug}_isbuynow` : '_local_isbuynow'
export const localIsPayingKey = (slug?: string) =>
  slug ? `_local_${slug}_ispaying` : '_local_ispaying'
export const localDiscountCodeKey = (slug?: string) =>
  slug ? `_local_${slug}_discount_code` : '_local_discount_code'
export const localSourceEventKey = (slug?: string) =>
  slug ? `_local_${slug}_source_event` : '_local_source_event'
export const localShippingAddressKey = '_local_shipping'
export const lastPLabelItem = '_local_last_p_label_item_'
export const isPayingWithPaypalKey = 'is_paying_paypal'
export const priceChangedVariantItemsKey = (slug?: string) =>
  slug ? `items_${slug}_changed_price` : 'items_changed_price'
export const sessionIdKey = '_local_session_id'
export const isPuchaseKey = (slug?: string) =>
  slug ? `_local_${slug}_is_puchase` : '_local_is_puchase'

export const stripePaymentConfirm = '_local_stripe_confirm'
export const localStripePaymentConfirmKey = (slug?: string) =>
  slug ? `_local_${slug}_stripe_confirm` : '_local_stripe_confirm'
export const localOtpOutKey = (slug?: string) => (slug ? `_local_${slug}_otpout` : '_local_otpout')
export const localpaymentTypeOrderKey = '_local_payment_type_order'

export const localIsCollectTax = '_local_is_collect_tax'
export const completeCard = '_is_local_complete_card'
export const localVisitTimeKey = '_local_local_visit'
export const eventIdFbKey = 'event_id_fb'

export const localIsPaypalThankYouId = (slug?: string) =>
  slug ? `_local_is_paypal_${slug}_thank_you` : 'is_paypal_thank_you'

export const localOrderPaypalId = (slug?: string) =>
  slug ? `_local_${slug}_paypal_order_id` : '_local_paypal_order_id'
export const isMaxQuantityDiscount = 'is_max_quantity_discount'
export const isMaxQuantityDiscountProduct = 'is_max_quantity_discount_product'
export const isMaxItemNegative = 'is_max_item_negative'
export const expiredDiscountKey = 'is_expired_discount'
export const tokenBrKey = 'token_br'
export const minPriceThankyou = 'min_price_thankyou'
export const isApplePayActive = 'is_apple_pay_active'

export const versionCookie = [
  'v13',
  'v14',
  'v141',
  'v142',
  'br1',
  'vx141',
  'v144',
  'e144',
  'v146',
  'a144',
  'c144',
  'a145',
  'b144',
  'ba10', // Add checked mark for verified buyer + Add checkmark on selection
  'ba11',
  'ba23',
  'bb23',
  'p144', // policy
  'd144',
  'o144',
  'g144',
  'r144',
  'l144',
  'i144',
  'x144', //base new
  'z144', //base new
  'k144', //base new
  'f144', //base new
  'y144', //base new
  'h144', //base new
  'vta1',
  'c145',
  'vta3',
  'vta31',
  'c146',
  'vta2',
  'usp1', //usp
  'usp2',
  'usp3',
  'usp4',
  'vcdn3',
  'acdn3',
  'vap1',
  'gp01a',
  'gp01b',
  'gp02',
  'vlz1',
  'vpo1',
  'vpl1',
  'vna2',
  'vli1',
  'vta4',
  'v145',
  'apv1', // Apple Pay
  'apv2', // Apple Pay,
  'apv21', // Apple Pay version base trên apv2
  'apv3', // New Apple Pay version for buy it now
  'm144', // New version base,
  'ab144', // New version base,
  'ac144', // New version base,
  'apv5', // enhanced version for Apple Pay,
  'apv6',
  'apv51'
]

export const listVersionUsp = ['usp1', 'usp2', 'usp3', 'usp4', 'p144', 'd144', 'v144']
export const gaExcludes = ['rosiedelight.com', 'maracharm.com']
export type showValueCD = 'NONE' | 'POPUP' | 'TEXT'

// export const axonHardcode = {
//   'ellymuse.com/daisy-bra': '965f10b6-7bd7-4004-85fb-8b1cf9ca1103',
//   'evybliss.com/moona-bra': 'a23541d8-8c33-4d7f-a168-5aaf13c08f72',
//   'sarapick.com/zenchic-bra': '833a4d19-41e1-4b16-a1de-18e517fc2093',
//   'evylady.com/ellebloom': '0fb0a82a-e54c-4dbc-a752-f099b980a28e',
//   'caralush.com/lushfitting': '5d560342-c017-42dd-bfae-f41b73ff5219',
//   'palcomfy.com/glidepants': 'bf87a8f9-33b2-4179-9e52-68a57bdb7396',
//   'acegents.com/multipants': '1649e3cb-85cc-4bb9-a182-2701c046fc94',
//   'modezier.com/jettjeans': '8de8d6b9-7f50-4fbb-af71-ae67bb3c7f24',
//   'dandyflex.com/easeflexor': '41b0587a-290b-430d-965b-50e65974e03f',
//   'janemore.com/comfijeans': '38c44243-517d-449b-b4b9-3837e3914e88',
//   'silamone.com/timelessflex': '5fb3ee44-73b4-4ad6-966c-07370d6d8380',
//   'rugstor.com/flexcamo': '0d4389a2-8531-4bfa-8e07-003d6d15e80e',
//   'luviess.com/bloomyfit': '9bd3c3ac-da41-4b69-a7d0-718ebfa6890e',
//   'erebloom.com/timelessmark': '3e590754-6e6a-49ba-8673-a6fb329a22c3',
//   'misease.com/easetrousers': '85d7a3be-d7ed-457f-be96-00ddae99f379',
//   'beeswan.com/timelessflex': '665c3f6d-38b9-409e-bdf6-14e46183e761',
//   'beeswan.com/stretchactive': '665c3f6d-38b9-409e-bdf6-14e46183e761',
//   'teststore.dev/timelesspant': '665c3f6d-38b9-409e-bdf6-14e46183e761'
// }
